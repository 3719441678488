import 'alpinejs'

import * as Sentry from '@sentry/browser'
import { Integrations } from "@sentry/tracing";

if(process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://e601f91235cc4736b6a5e66d4d61a298@o219725.ingest.sentry.io/1364523',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

import Vuelidate from 'vuelidate'
import ClickOutside from 'vue-click-outside'
import 'cookieconsent'
import Cleave from 'cleave.js'
import axiosInstance from 'lib/axiosInstance'
import bchaddr from 'bchaddrjs'
import WAValidator from 'wallet-address-validator'
import { required, and } from 'vuelidate/lib/validators'
import AsyncComputed from 'vue-async-computed'

Vue.use(Vuelidate)
Vue.use(AsyncComputed)

/*
|-------------------------------------------------------------------------------
| Clipboard
|-------------------------------------------------------------------------------
*/
import Clipboard from "clipboard"

document.addEventListener("turbolinks:load", () => {
  var clipboard = new Clipboard('.clipboard-btn');
})

/*
|-------------------------------------------------------------------------------
| Debounced
|-------------------------------------------------------------------------------
*/

import debounced from "debounced"
debounced.initialize({ ...debounced.events, input: { wait: 400 }, keyup: { wait: 400 }})

/*
|-------------------------------------------------------------------------------
| Uppy
|-------------------------------------------------------------------------------
*/
import 'vendor/uppy/uppy.config.js'

/*
|-------------------------------------------------------------------------------
| International phone select
|-------------------------------------------------------------------------------
*/
import 'intl-tel-input/build/css/intlTelInput.css'

/*
|-------------------------------------------------------------------------------
| Tailwind
|-------------------------------------------------------------------------------
*/
import '../styles/application.scss'

/*
|-------------------------------------------------------------------------------
| Wysiwyg Editor: Trix
|-------------------------------------------------------------------------------
*/
import 'trix/dist/trix.css'
import 'trix/dist/trix.js'
/*
|-------------------------------------------------------------------------------
| Semantic UI, Dropdown component
|-------------------------------------------------------------------------------
*/
import 'semantic-ui-css/components/transition.min.css'
import 'semantic-ui-css/components/transition.min.js'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/dropdown.min.js'
import 'semantic-ui-css/components/progress.min.css'
import 'semantic-ui-css/components/progress.min.js'

/*
|-------------------------------------------------------------------------------
| Stimulus
|-------------------------------------------------------------------------------
*/
import Rails from '@rails/ujs'
Rails.start()

import Turbolinks from 'turbolinks'
Turbolinks.start()

import "channels"
import "controllers"

/*
|-------------------------------------------------------------------------------
| Uppy
|-------------------------------------------------------------------------------
*/

import "../vendor/uppy/uppy.config.js"

/*
|-------------------------------------------------------------------------------
| jstz - Browser Time Zone
|-------------------------------------------------------------------------------
*/
import jstz from 'jstz'

function setCookie(name, value) {
  var expires = new Date()
  expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000))
  document.cookie = name + '=' + value + ';expires=' + expires.toUTCString()
}
const timezone = jstz.determine()
setCookie("timezone", timezone.name())

/*
|-------------------------------------------------------------------------------
| Users
|-------------------------------------------------------------------------------
*/

import 'channels/optimism_channel.js'
// import 'channels/presence.js.erb' Temporal disabled
import 'lib/users/security/two_factor/disable_totp.js'
import 'lib/users/settings/p2p/working_hours_form.js'
import 'lib/components/progressBar.js'
import 'lib/components/trade/escrowWarningAgreed'
import 'lib/vue-mixins/destroyOnTurbolinkVisit'
import { setupSoundNotifications } from 'lib/soundNotifications'

setupSoundNotifications();

/*
|-------------------------------------------------------------------------------
| QRious
|-------------------------------------------------------------------------------
*/
import QRious from 'qrious/dist/qrious.js'
window.QRious = QRious;

function runApp() {
  let tradeTab = document.getElementById('trades-tab')
  if(tradeTab != undefined) {
    Promise.all([
      import('lib/store'),
      import('lib/components/tradeTab')
    ])
    .then(([store, tradeTabInit]) => tradeTabInit.default(store.default, tradeTab))
  }


  let two_factor_qr_canvas = document.getElementById('two_factor_qr')
  if (two_factor_qr_canvas != undefined) {
    new QRious({
      element: two_factor_qr_canvas,
      value: two_factor_qr_canvas.dataset.address,
      // level: "H",
      size: 500
    });
  }

  /*
  |-----------------------------------------------------------------------------
  | User dropdown menu
  |-----------------------------------------------------------------------------
  */
  // TODO: Move to independent file
  var element = document.querySelector('#primary_nav_avatar_menu')
  if (element != undefined) {
    new Vue({
      el: element,
      data: {
        show: false
      },
      methods: {
        close: function() {
          this.show = false
        }
      },
      directives: {
        ClickOutside
      }
    })
  }

  /*
  |-----------------------------------------------------------------------------
  | Language dropdown menu
  |-----------------------------------------------------------------------------
  */
  // TODO: Move to independent file
  var element = document.querySelector('#primary_nav_language_menu')
  if (element != undefined) {
    new Vue({
      el: element,
      data: {
        show: false
      },
      methods: {
        close: function() {
          this.show = false
        }
      },
      directives: {
        ClickOutside
      }
    })
  }

  /*
  |-----------------------------------------------------------------------------
  | Visitor dropdown menu
  |-----------------------------------------------------------------------------
  */
  // TODO: Move to independent file
  var element = document.querySelector('#mobile_nav')
  if (element != undefined) {
    new Vue({
      el: element,
      data: {
        show: false
      },
      methods: {
        close: function() {
          this.show = false
        }
      },
      directives: {
        ClickOutside
      }
    })
  }

  /*
  |-----------------------------------------------------------------------------
  | Reviews profile view
  |-----------------------------------------------------------------------------
  */
  var element = document.querySelector('#profile_reviews')
  if (element != undefined) {
    new Vue({
      el: element,
      data: {
        starStyle: 'text-green-800'
      }
    })
  }

  const offerForm = document.getElementById("offer-form")
  if (offerForm) {
    Promise.all([
      import('lib/store'),
      import('lib/components/users/offerCreationForm')
    ])
    .then(([store, offerFormInit]) => offerFormInit.default(store.default, offerForm, false))
  }

  const tradeChat = document.getElementById('trade-chat')
  const tradeOperationsWrapper = document.getElementById('trade-operations')
  if(tradeChat && tradeOperationsWrapper) {
    Promise.all([
      import('lib/store'),
      import('lib/components/trade/chat'),
      import('lib/components/trade/operations')
    ])
    .then(([store, chatInit, tradeOperationsInit]) => {
      chatInit.default(store.default, tradeChat)
      tradeOperationsInit.default(store.default, tradeOperationsWrapper)
    })
  }

  const tradeIndex = document.getElementById('trades-index');
  if(tradeIndex) {
    Promise.all([
      import('lib/store'),
      import('lib/components/tradeIndex')
    ])
    .then(([store, tradeIndexInit]) => tradeIndexInit.default(store.default, tradeIndex))
  }

  if (true) {
    cookieconsent.initialise({
      "content": {
       "header": "Cookies & Privacidad",
       "message": "Este sitio web utiliza cookies para garantizar que obtenga la mejor experiencia.",
       "dismiss": "Aceptar",
       "allow": "Permitir cookies",
       "link": "Mas información",
       "href": "http://help.ccoins.io/espanol/politica-de-cookies",
       "close": '&#x274c;',
       "policy": "Cookie Policy",
       "target": '_blank',
      },
      "palette": {
        "popup": {
          "background": "#7E7E7E",
          "text": "#F7F7F7"
        },
        "button": {
          "background": "#7BB026",
          "text": "#ffffff"
        }
      },
      "theme": "classic"
    })
  }
}

document.addEventListener('turbolinks:load', function(){
  runApp();
})
